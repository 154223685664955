<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ $t('pages.ecommerce.order.shipping.detail.title') }}
        </h2>
    </div>
    <div class="d-flex flex-column flex-lg-row">
        <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0" v-if="detail.movements && detail.movements.length">
            <div class="card pt-4 mb-xl-10">
                <div class="card-header border-0">
                    <div class="card-title">
                        <h2>{{ $t('pages.ecommerce.order.shipping.detail.movement.title') }}</h2>
                    </div>
                </div>

                <div class="card-body py-0">
                    <div class="table-responsive">
                        <table class="table align-middle table-row-dashed fw-bold text-gray-600  fs-6 gy-5" id="kt_table_customers_logs">
                            <tbody>
                                <template v-for="(movement, movementIndex) in detail.movements" :key="movementIndex">
                                    <tr>
                                        <td class="min-w-70px">
                                            <div class="badge" :class="movementStatus[movement.status_id].badge">
                                                {{ $t('pages.ecommerce.order.shipping.detail.movement.status.' + [movementStatus[movement.status_id].code]) }}
                                            </div>
                                        </td>

                                        <td>
                                            {{ movement.message }}
                                        </td>

                                        <td class="pe-0 text-end min-w-200px">
                                            {{ $moment.unix(movement.date).format('DD.MM.YYYY HH:mm') }}
                                        </td>
                                    </tr>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xl-300px mb-10 order-1 order-lg-2" v-if="detail.details && Object.keys(detail.details).length">
            <div class="card card-flush pt-3 mb-5 mb-lg-10">
                <div class="card-header">
                    <div class="card-title">
                        <h2>{{ $t('pages.ecommerce.order.shipping.detail.title') }}</h2>
                    </div>
                </div>
                <div class="card-body pt-0 fs-6">
                    <div class="mb-7">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.shipping.detail.cols.orderNo') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ orderID }}</span>
                        </div>
                    </div>
                    <div class="separator separator-dashed mb-7"></div>

                    <div class="mb-10">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.shipping.detail.cols.received') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ $t('pages.ecommerce.order.shipping.detail.receivedStatus.' + (detail.details.received ? 'yes': 'no')) }}</span>
                        </div>
                    </div>

                    <div class="separator separator-dashed mb-7"></div>

                    <div class="mb-10">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.shipping.detail.cols.receivingPerson') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ detail.details.receiving_person ?? "-" }}</span>
                        </div>
                    </div>

                    <div class="separator separator-dashed mb-7"></div>

                    <div class="">
                        <h5 class="mb-3">{{ $t('pages.ecommerce.order.shipping.detail.cols.receivingDate') }}</h5>

                        <div class="mb-0">
                            <span class="fw-bold text-gray-600">{{ ((detail.details.receiving_date) ? $moment.unix(detail.details.receiving_date).format('DD.MM.YYYY HH:mm') : "-") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "_id",
    data(){
        return {
            detail: {},
            movementStatus: {
                1: {
                    code: 'accepted',
                    badge: 'badge-primary'
                },
                2: {
                    code: 'atSenderBranch',
                    badge: 'badge-warning'
                },
                3: {
                    code: 'onTheRoad',
                    badge: 'badge-light'
                },
                4: {
                    code: 'atReceiverBranch',
                    badge: 'badge-info'
                },
                5: {
                    code: 'delivered',
                    badge: 'badge-success'
                }
            }
        }
    },
    computed: {
        orderID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (!this.orderID || !(this.orderID > 0)) {
            this.$router.back();
        }
    },
    mounted() {
        if (this.orderID && this.orderID > 0) {
            this.loadOrderShippingDetail();
        }
    },
    methods: {
        loadOrderShippingDetail(){
            this.axios.get(this.endpoints['ecommerce_order_shipping_detail'] + '/' + this.orderID).then((response) => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.detail = data;
                });
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            });
        }
    }
}
</script>

<style scoped>

</style>